<template>
  <div class="GlobalFormDemo">
    <el-form :model="formData" inline label-width="80px" size="small" class="el-form-group_isRound">
      <el-form-item label="机型名称" prop="name">
        <el-input v-model="formData.name" clearable placeholder="请输入机型名称" />
      </el-form-item>
      <el-form-item label="所属分类" prop="machineTypeId">
        <el-select v-model="formData.machineTypeId" clearable placeholder="请选择分类">
          <el-option v-for="item in classList" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="所属品牌" prop="brandId">
        <el-select v-model="formData.brandId" filterable clearable placeholder="请选择品牌">
          <el-option v-for="item in oldBrand" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="机型标记" prop="mark">
        <el-select v-model="formData.mark" clearable placeholder="请选择机型标记">
          <el-option v-for="item in markOptions" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="机型来源" prop="channel">
        <el-select v-model="formData.channel" multiple placeholder="请选择">
          <el-option v-for="item in channelList" :key="item.key" :label="item.value" :value="item.key" />
        </el-select>
      </el-form-item>
      <el-form-item label="查询机型" prop="machineName">
        <el-input v-model="formData.machineName" clearable placeholder="请输入机型名称" />
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" :loading="searchLoading" round @click="handleConfirm">查询</el-button>
      </el-form-item>
      <el-form-item style="float: right">
        <el-button size="small" type="warning" icon="el-icon-refresh" @click="renewModel">更新机型</el-button>
        <el-button size="small" type="success" icon="el-icon-upload" @click="addModel('import')">批量导入机型</el-button>
        <el-button size="small" type="primary" icon="el-icon-plus" @click="addModel">添加机型</el-button>
      </el-form-item>
    </el-form>
    <!--弹框-->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="500px"
      @closed="handleDialogClosed"
    >
      <el-form ref="form" :model="modelform" label-width="80px" v-if="dialogTitle=='添加机型'">
        <el-form-item label="所属分类">
          <el-select v-model="modelform.machineTypeId" placeholder="请选择分类">
            <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属品牌">
          <el-select v-model="modelform.brandId" filterable placeholder="请选择品牌">
            <el-option v-for="ele in oldBrand" :key="ele.id" :label="ele.name" :value="ele.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="机型名称">
          <el-input v-model="modelform.name"  placeholder="请输入机型名称（限20字以内）"></el-input>
        </el-form-item>
      </el-form>
      <div class="step" v-else>
        <div class="step-title">
          <span>Step1</span>
        </div>
        <div class="step-content">
          <span style="marginRight:12px">表格下载</span>
          <el-link type="primary" @click="download">点击下载模板表格</el-link>
        </div>
        <div class="step-title">
          <span>Step2</span>
        </div>
        <div class="tip">
          <span class="tip-title">上传表格</span>
          <span class="tip-content">请上传.xls或.xlsx格式的excel文件，大小10M内</span>
        </div>
        <div class="step2-content">
          <el-upload ref="upload"
            :limit="1"
            :auto-upload="false"
            :http-request="uploadFile"
            :headers="headers"
            :before-upload="onUpload"
            accept='.xls,.xlsx'
            action="customize">
          <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
        </el-upload>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click.native="handleSuccess">确定</el-button>
        <el-button @click.native="handleClose">取消</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title='更新机型提示'
      :visible.sync="commandShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="commandClosed"
    >
      <div class="enable-tip">
        <div>说明：确定后同时更新手机分类的机型和品牌，一天只能更新一次。</div>
        <div class="tip-sure">是否确认更新机型？</div>
      </div>
      <div class="command">
        <div style="width: 85px;">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="commandClosed">取消</el-button>
        <el-button type="primary" :loading="butLoading" @click.native="renewSubmit"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {formValidateMobile} from '@/utils/form-validate'
import environments from '@/config/url'
import _api from "@/utils/request";
import { encryptByDES} from '@/utils/3DES'
const uploadUrl = environments[process.env.VUE_APP_BASE_URL].uploadBaseUrl
const fileUrl = environments[process.env.VUE_APP_BASE_URL].fileUrl
export default {
  name: 'Form',
  props:{
    classList:Array,
    oldBrand:Array,
    channelList: Array
  },
  data() {
    return {
      fileUrl,
      uploadUrl,
      headers:{
        'ACCESS_TOKEN': localStorage.getItem('token'),
        'channel':'platformPc',
        'TOKEN':''
      },
      modelName:"",
      machineTypeId: "",
      brandId:"",
      dialogTitle:"",
      dialogVisible:false,
      commandShow: false,
      butLoading: false,
      command: "",
      modelform:{},
      file:null,
      formRules: {
        mobile: [{
          validator: formValidateMobile,
          trigger: 'blur'
        }]
      },
      formData: {
        name: "",
        machineTypeId: "",
        brandId: "",
        mark: "",
        channel: []
      },
      // channelOptions: [],
      markOptions: [
        {
          label: "未标记机型",
          value: "10",
        },
        {
          label: "折叠屏机型",
          value: "00",
        },
        {
          label: "曲面屏机型",
          value: "01",
        },
      ],
      searchLoading: false
    }
  },
  created(){
    // this.getChannelList()
    this.classList.forEach(ele => {
      ele.label = ele.name
      ele.value = ele.id
    })
    this.oldBrand.forEach(ele => {
      ele.label = ele.name
      ele.value = ele.id
    })
    this.handleConfirm()
  },
  methods: {
    // getChannelList() {
    //   _api.getMachineChannelList().then(res => {
    //     this.channelOptions = res.data
    //   })
    // },
    //查询
    handleConfirm() {
      console.log(this.formData);
      let _this = this
      this.searchLoading = true
      const SeachParams = {
        ...this.formData,
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
          if (typeof _this.stopSearchLoading === 'function') {
            _this.stopSearchLoading()
          }
        },
      }
      this.$nextTick(() => {
        this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
      })
    },
    handleDialogClosed() {
      this.dialogVisible = false;
    },
    // 弹窗取消
    handleClose() {
      this.dialogVisible = false;
    },
    // 添加机型
    addModel(val) {
      if(val == 'import'){
      this.dialogTitle = "批量导入机型";
      }else{
      this.dialogTitle = "添加机型";
      }

      this.dialogVisible = true;
    },
    handleSuccess(){
       this.dialogVisible = false;
       if(this.dialogTitle == '添加机型'){
         _api.modelAdd(this.modelform).then(res => {
           if (res.code === 1) {
             this.$message.success("操作成功");
            //  const SeachParams = {
            //    pageNum: 1,
            //    pageSize: 10,
            //  }
            //  this.$store.commit('tagsView/SEACH_PARAMS',SeachParams)
             this.handleConfirm()
           } else {
             this.$message.error("操作失败");
           }
         })
       }else{
         this.$refs.upload.submit();
       }
    },
    uploadFile(params){
      console.log("uploadFile", params);
      const _file = params.file;
      // 通过 FormData 对象上传文件
      var formData = new FormData();
      formData.append("file", _file);
      // 发起请求
      _api.importAdd(formData).then(res => {
        console.log("_RequestUploads_", res);
        if (res.code === 1) {
          this.$message({
            type: "success",
            message: res.msg
          });
        } else {
          this.$message({
            type: "warning",
            message: res.msg
          });
        }
      })
    },
    // 筛选条件
    download () {
      const link = document.createElement('a')
      link.href = "/static/导入机型模板.xlsx"
      link.setAttribute('download', '导入机型模板.xlsx')
      document.body.appendChild(link)
      link.click()
    },
    onUpload(e) {
			this.headers.TOKEN = encryptByDES(encryptByDES((new Date().getTime()).toString(),'63e42c2444e94c1ebca21d30d2aa39a5'),localStorage.getItem('token') || '')
		},
    renewModel() {
      this.commandShow = true
    },
    commandClosed() {
      this.command = ""
      this.commandShow = false
    },
    renewSubmit() {
      this.butLoading = true
      _api.modelRefresh({command: this.command}).then(res => {
        if(res.code === 1){
          this.commandShow = false
          console.log(res);
        }
        this.butLoading = false
      }).catch(err => {
        this.butLoading = false
      })
    },
    stopSearchLoading() {
      this.searchLoading = false
    }
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.GlobalFormDemo{
  .import-img{
    vertical-align: middle ;
  }
  .step{
    .step-title{
      color: #0981FF;
      margin-bottom: 10px;
      padding-left: 6px; 
      border-left: 3px solid ;
      border-radius: 1.5px;
    }
    .step-content,.step2-content{
      margin-bottom: 26px;
    }
    .step2-content>div{
      display: flex;
    }
    .step2-content{
      .upload-demo{
      display: flex;
      justify-content: start;
      .el-upload-list{
        width: 200px;
      }
      }
      
    }
    .tip{
      margin-bottom: 12px;
      .tip-title{
        color: #666666;
        margin-right: 12px;
      }
      .tip-content{
        color: #FF687B;
        font-size: 12px;
      }
    }
    
  }
  .file-input{
    width: 340px;
    height: 34px;
    line-height: 34px;
    display: block;
    border: 1px solid #ccc;
    padding: 0 5px;
  }
  .enable-tip{
    color: #FF8080;
    font-size: 14px;
    margin-bottom: 20px;
    .tip-sure{
      margin-top: 10px;
      color:#333333;
      font-size:16px;
      text-align: center;
    }
  }
  .command{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
}
</style>

<template>
  <div class="oldBrand-list">
    <GlobalInfoBar
        title="机型管理"
        content="管理门店店员在报价时，在提交订单时提供机型的快捷查找"
    />
    <GlobalChunk
        icon="search"
        title="筛选查询"

      >

      <!-- <div style="text-align: right;margin-bottom: 20px">
        <el-button size="small" type="primary" icon="el-icon-plus" @click="$router.push('/oldBrand/add')">添加品牌</el-button>
      </div> -->
      
      <div slot="filter">
        <ResidentFrom :classList="classList" :oldBrand="oldBrand" :channelList="channelList" v-if="classList && oldBrand" />
      </div>

      <ResidentTable :classList="classList" :oldBrand="oldBrand" :channelList="channelList" v-if="classList && oldBrand"/>
    </GlobalChunk>
  </div>
</template>

<script>
import _api from "@/utils/request";
import ResidentFrom from './From'
import ResidentTable from './Table'
export default {
  name: 'oldBrand-list',
  components: {ResidentFrom,ResidentTable},
  data(){
    return{
      brandName: "",
      classList: null,
      oldBrand:null,
      channelList: []
    }
  },
  created() {
    this.getClassList()
    this.getResidentList()
    this.getChannelList()
  },
  methods:{
    handleCurrentChangeTable(){
      const params = {
        merchantType:"01",
        brandName:this.brandName,
        companyId: 0,
        pageNum: 1,
        pageSize: 10,
      }
      _api.ResidentTable(params).then(res =>{
        if(res.code===1){
          this.seachDataList = res.data.records
          console.log(this.seachDataList)
          this.$store.commit('tagsView/POST_MERCHANTDATA', this.seachDataList)
          this.$store.commit('tagsView/POST_TOTAL',res.data.total)

        }
      })
    },
    // 获取分类集合
    getClassList() {
      _api.selectList().then(res => {
        this.classList = res.data?res.data:[]
      })
    },
    // 获取旧机品牌列表
    getResidentList() {
      _api.ResidentList().then(res => {
        this.oldBrand = res.data
      })
    },
    getChannelList() {
      _api.getMachineChannelList().then(res => {
        this.channelList = res.data
      })
    },
  }
}
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.merchant-list{
}
</style>
